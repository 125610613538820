import {createStore} from 'vuex'
import game from "@/store/modules/game";

export default createStore({
    state: {
        counter: 10,
        data: {},
        colorCode: 'blue',
    },
    mutations: { // commit mutations, synchronous. It's generally a good idea to have a matching set of mutations & actions
        increaseCounter(state) {
            state.counter += 1;
        },
        updateGames(state, data) {
            state.data = data;
        },
        setColorCode(state, newValue) {
            state.colorCode = newValue;
        }
    },
    actions: { // dispatch actions, async. Can read state, can read through mutations
        updateGames({commit}) {
            const data = fetch('').then(r => r.formData())
            commit('updateGames', data);
        },
        async addRandomNr(context) { // context includes all store accessors
            context.commit('some-action name')
        },
        setColorCode({commit}, newValue) {
            commit('setColorCode', newValue);
        },
    },
    getters: { // with getters we  can change or filter the state, before it is made available to out components
        doneTodosCount(state, getters) { // getters will also receive other getters as 2-nd argument
            return getters.getHello + state.colorCode;
        },
        getHello() {
            return 'hello: ';
        }
    },
    modules: {
        game,
    }
})

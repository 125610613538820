import {IGameStore, IState} from "vue";
import {Module} from "vuex";

const gameModule: Module<IGameStore, IState> = { // <ModuleState, RootState>
    namespaced: true,

    state: {
        items: ['a', 'b'],
        checkoutStatus: 'DONE',
    },

    getters: {
        itemsLength(state, getters, rootState): number {
            // let itemsLength = getters.itemsLength;
            // let r = rootState.colorCode;
            return state.items.length;
        }
    },

    mutations: {
        addLevelToNavigation(state: any, item: any) {
            if (state.currentPosition) {
                state.breadcrumb.push(state.currentPosition);
            }
            state.currentPosition = item;
        },

        removeLevelFromNavigation(state: any) {
            state.currentPosition = state.breadcrumb.pop();
        },

        clearNavigationState(state: any) {
            state.currentPosition = null;
            state.breadcrumb = [];
        }
    },

    actions: {}
};

export default gameModule;

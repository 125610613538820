
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'HelloWorld',
  // inheritAttrs: false, // will not inherit any attributes passed to component

  //emits: ['inFocus', 'submit'], // can do array syntax or object, its just that with object syntax, we can perform validation

  /*emits: {
    // No validation
    click: null,

    // Validate submit event
    submit: ({ email, password }) => {
      if (email && password) {
        return true
      } else {
        console.warn('Invalid submit event payload!')
        return false
      }
    }
  },*/
  props: {
    msg: { // props can have validation, match any interface you may want
      type: String,
      required: true,
      // default: 'nipitiri',
      // type: Object,
      // Object or array defaults must be returned from a factory function
      // default: function() {
      //   return { message: 'hello' }
      // }
    } // as Book
  },
  methods: {
    getAttrs() {
      return this.$attrs; // style, and id attributes, that have been passed into this component
    }
  }
});

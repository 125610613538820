
import enums from '@/core/enums/enums';
import {defineComponent, reactive, inject, ref} from 'vue';
import HelloWorld from '@/components/HelloWorld.vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {useToast} from "vue-toastification";
import router from "@/router";
import {GameCategoryEnum} from "@/core/enums/gameCategoryEnum";
import {GameDifficultyEnum} from "@/core/enums/gameDifficultyEnum"; // @ is an alias to /src

interface Book {
  title: string
  year?: number
}

export default defineComponent({
  name: 'Home',
  components: {
    HelloWorld,
  },
  setup(props, {attrs, slots, emit}) {
    const dataStore: any = inject('dataStore');

    const state = reactive({
      count: 0
    });

    const onLessonClick = (selection: string) => {
      let selectionObjectData = {};
      switch (selection) {
        case enums.LEVEL_ONE_MATH:
          selectionObjectData = {difficulty: GameDifficultyEnum.INTRODUCTION, category: GameCategoryEnum.ADDING}
          break;
        case enums.LEVEL_ONE_WORDS:
          selectionObjectData = {difficulty: GameDifficultyEnum.PRE_K, category: GameCategoryEnum.COUNTING}
          break;
        case enums.LEVEL_TWO_MATH:
          selectionObjectData = {difficulty: GameDifficultyEnum.Mid_K, category: GameCategoryEnum.MULTIPLICATION}
          break;
        case enums.LEVEL_TWO_WORDS:
          selectionObjectData = {difficulty: GameDifficultyEnum.Mid_K, category: GameCategoryEnum.WORDS}
          break;
      }
      const selectionData = JSON.stringify(selectionObjectData);

      router.push({
        name: 'Play',
        params: {selectionData}
      })
    }

    const onPlay = () => {
      warnToLogin();
    }

    const warnToLogin = () => {
      if (!dataStore.state.loggedIn)
        useToast().success('Logi sisse, et oma tulemused salvestada!');
    }

    return {
      state,
      dataStore,
      onLessonClick,
      onPlay,
      enums,
    }
  },
  emits: {
    // addBook(payload: { bookName: string }) {
    // perform runtime validation
    // return payload.bookName.length > 0
    // }
  },
  // data: {
  /*book: {
    type: Object as PropType<Book>,
    required: true
  }*/
  // },
  computed: { // will return a cashed value, if any of the dependant elements change, returned value will be re-computed
    /*colorCode: {
      get() {
        return this.$store.state.colorCode;
      },
      set(newValue) {
        console.log(newValue);
        this.$store.dispatch('setColorCode', newValue);
      }
    },*/
    /*styleObject: {
      color: 'red',
      fontSize: '13px'
    }*/
    ...mapGetters('game', ['itemsLength']),
  },
  /*watch: {
    firstName(val) {
      this.fullName = val + ' ' + this.lastName
    },
  },*/
  methods: {
    ...mapMutations(["increaseCounter"]),
    // ...mapMutations(),
    // ...mapActions(['updateGames']),
    /*onSubmit() {
      console.log(this.$store.state.counter);
      console.log(this.$store.getters['game/itemsLength']); // works!
      console.log(this.itemsLength); // works !!

      console.log(this.$store.getters.getHello);
      this.increaseCounter();
      // this.$emit('addBook', {
      //   bookName: "Hello from event addBook" // Type error!
      // })
      // this.$emit('non-declared-event') // Type error!
    }*/
  }
});

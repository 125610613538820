<template>
  <div id="top-bar">
    <div class="container-fluid p-0" style="border-bottom: black solid 2px">

      <nav class="navbar navbar-light" style="background-color: #FFF087">

        <div class="container-fluid">

          <button class="navbar-toggler" style="border-color: black" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
                  aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="d-flex justify-content-center align-items-center">
            <img class="img-fluid"
                 style="transform: translateX(-50%); left: 50%; max-height: 60%; position: absolute; max-width: 45vw; cursor: pointer;"
                 src="../assets/logo.svg" alt="logo" @click="onLogoClick">
          </div>

          <div class="d-flex">

            <router-link to="/login" v-if="dataStore.state.loggedIn === false">
              <button class="btn btn-outline-success" type="submit">Logi sisse</button>
            </router-link>

            <div v-else class="btn-group">

              <div class="justify-content-center me-1">
                <p class="m-0" style="font-size: 14px;">Punktid:</p>
                <p class="m-0" style="margin-top: -3px !important;">{{ dataStore.state.userPoints }}</p>
              </div>

              <a id="imageDropdown" data-bs-toggle="dropdown" style="cursor: pointer">
                <img class="rounded-circle z-depth-2" style="height: 45px; width: 45px;" src="@/assets/owl.svg"
                     alt="profile">
              </a>

              <ul class="dropdown-menu dropdown-menu-end" style="cursor: pointer">
                <h6 class="px-3 text-" >{{ dataStore.state.username }}</h6>

                <hr class="border-dark my-1">

                <li>
                  <router-link to="/profile" style="text-decoration: unset">
                    <button class="dropdown-item" type="button">Profiil</button>
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile-groups" style="text-decoration: unset">
                    <button class="dropdown-item" type="button">Minu grupid</button>
                  </router-link>
                </li>
                <li>
                  <button class="dropdown-item text-danger" type="button" @click="onLogout">Logi välja
                  </button>
                </li>
              </ul>

            </div>

          </div>

        </div>
      </nav>

      <div class="collapse" id="navbarToggleExternalContent">
        <div class="bg-dark p-4">

          <router-link to="/">
            <button class="btn btn-outline-light m-2" type="submit">Kodu</button>
          </router-link>
          <router-link to="/play">
            <button class="btn btn-outline-light m-2" type="submit">Mängima</button>
          </router-link>
          <router-link to="/about">
            <button class="btn btn-outline-light m-2" type="submit">Meist</button>
          </router-link>

          <!--          <h5 class="text-white h4">Collapsed content</h5>-->
          <!--          <span class="text-muted">Toggleable via the navbar brand.</span>-->
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import router from "@/router";

export default defineComponent({
  name: 'TopBar',
  props: {},
  setup(props, {attrs, slots, emit}) { // style, and id attributes, that have been passed into this component
    const dataStore: any = inject('dataStore');

    const onLogoClick = () => {
      router.push({name: 'Home'});
    };

    const onLogout = () => {
      dataStore.tokenGetters.tokenComputed.value = false; // remove token reactively

      dataStore.state.userPoints = 0;
      dataStore.state.username = '';
      dataStore.state.userRoles = [];
      dataStore.state.firstName = '';
      dataStore.state.lastName = '';
      dataStore.state.groupUsername = '';
      dataStore.state.birthday = '';
      dataStore.state.groups = [];
      dataStore.state.isUser = false;
      dataStore.state.isAdmin = false;
      dataStore.state.isGroupUser = false;


      router.push('/');     // TODO return home and points disappearing after logout and login
    }

    return {
      onLogoClick,
      dataStore,
      onLogout
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
</style>

import axios, {AxiosError, AxiosResponse} from 'axios';
import {useToast} from 'vue-toastification'
import enums from "@/core/enums/enums";
import IResolveGameRequest from "@/core/interfaces/IResolveGameRequest";
import {ILogin} from "@/core/interfaces/ILogin";
import {IRegister} from "@/core/interfaces/IRegister";
import {IGamesListResponse} from "@/core/interfaces/IGamesListResponse";
import {IGameResponse} from "@/core/interfaces/IGameResponse";
import IResolveGameResponse from "@/core/interfaces/IResolveGameResponse";
import DataStore from "@/store/composition/dataStore";
import Configuration from '@/ConfigProvider';

// const DOMAIN_AND_PORT = process.env.VUE_APP_SERVER_DOMAIN_AND_PORT;
const DOMAIN_AND_PORT = Configuration.value('VUE_APP_SERVER_DOMAIN_AND_PORT');

const URL = {
    BASE_URL: DOMAIN_AND_PORT + '/v1/api/',
    LOGIN: 'auth/login/',
    REGISTER: 'auth/register/',

    GET_GAMES_LIST: 'get-games-list/',
    GET_GAMES_LIST_PUBLIC: 'get-games-list-public/', // not logged in users
    GET_GAME: 'get-game/',
    RESOLVE_GAME: 'resolve-game/',
    RESOLVE_GAME_PUBLIC: 'resolve-game-public/',
    USER_DATA: 'user-data/',
    GROUP_DATA: 'group-data/',

    ADD_GROUP: 'add-group/',
    REMOVE_GROUP: 'remove-group/',
    ADD_GROUP_USER: 'add-group-user/',
    REMOVE_GROUP_USER: 'remove-group-user/',
}

const axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
};

export interface IGetGameListReqParams {
    difficulty: string,
    category: string
}

export default class Api {
    public static axios: any;

    static init() {
        Api.axios = axios.create({
            baseURL: URL.BASE_URL,
        });
        Api.axios.defaults.baseURL = URL.BASE_URL;
        Api.axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem(enums.ACCESS_TOKEN);

        // Global handlers
        Api.axios.interceptors.response.use((response: AxiosResponse) => {
            // Do something with response data
            return response;
        }, function (error: AxiosError) {
            if (error?.response?.data?.message === 'Unauthorized') {
                useToast().warning('Viga päringu tegemisel, puuduvad õigused!');
            }
            // Do something with response error
            return Promise.reject(error);
        });

        // Api.axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
        // Send a POST request
        /* Api.axios({
            method: 'post',
            url: '/user/12345',
            params: { // query params e.g. "asd?id=123"
                ID: 12345
            }
            data: {
                firstName: 'Fred',
                lastName: 'Flintstone'
            }
        }).then(function (response) {
            console.log(response.data); // success
        });*/
    }

    public static login(loginDTO: ILogin) {
        return Api.axios.post(URL.LOGIN, loginDTO)

            .then((res: any) => {
                const token = res.data['access_token'];
                if (!token) console.warn("saving token to local storage with value: " + token)
                window.localStorage.setItem(enums.ACCESS_TOKEN, token);
                Api.axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem(enums.ACCESS_TOKEN);

                return Promise.resolve(res);
            })

            .catch((err: AxiosError) => {
                if (err.isAxiosError) {
                    const statusText = 'Vigane kasutajanimi või parool';
                    useToast().error(statusText);
                }
                return Promise.reject(err);
            })
    }

    public static register(registerDTO: IRegister) {
        return Api.axios.post(URL.REGISTER, registerDTO)

            .then((res: any) => {
                const statusText = 'Kasutaja loodud! Palun logige sisse';
                useToast().success(statusText);
                return Promise.resolve(res);
            })

            .catch((err: AxiosError) => {
                if (err.isAxiosError) {
                    const statusText = 'Email on juba kasutuses või on vigane';
                    useToast().error(statusText);
                }
                return Promise.reject(err);
            })
    }

    public static getGamesList(data?: IGetGameListReqParams): Promise<IGamesListResponse[]> {
        let requestUrl: string = localStorage.getItem(enums.ACCESS_TOKEN) ? URL.GET_GAMES_LIST : URL.GET_GAMES_LIST_PUBLIC;
        return Api.axios.get(requestUrl, {params: data})
            .then((res: any) => {
                return Promise.resolve(res.data as IGamesListResponse[]);
            })
    }

    public static getGame(id: number): Promise<IGameResponse> {
        return Api.axios.get(URL.GET_GAME, {params: {id}})
            .then((res: any) => {
                return Promise.resolve(res.data as IGameResponse);
            })
    }

    public static resolveGame(data: IResolveGameRequest): Promise<IResolveGameResponse> {
        let requestUrl: string = localStorage.getItem(enums.ACCESS_TOKEN) ? URL.RESOLVE_GAME : URL.RESOLVE_GAME_PUBLIC;
        return Api.axios.post(requestUrl, data)
            .then((res: any) => {
                return Promise.resolve(res.data as IResolveGameResponse);
            })
    }

    public static async getUserData(dataStore: any): Promise<any> {
        const res: any = await Api.axios.get(URL.USER_DATA)
        if (!res.data) return;

        DataStore.state.userPoints = res.data.points;
        DataStore.state.firstName = res.data.firstName;
        DataStore.state.lastName = res.data.lastName;
        DataStore.state.groupUsername = res.data.groupUsername;
        DataStore.state.birthday = res.data.birthday;
        DataStore.state.groups = res.data.groups;

        dataStore.state.isUser = dataStore.state.userRoles.some((role: string) => role === 'user' || role === 'admin');
        dataStore.state.isAdmin = dataStore.state.userRoles.some((role: string) => role === 'admin');
        dataStore.state.isGroupUser = dataStore.state.userRoles.some((role: string) => role === 'groupUser');

        return Promise.resolve();
    }

    public static updateProfile(data: any): Promise<any> {
        return Api.axios.post(URL.USER_DATA, data)
    }

    public static getGroupData(): Promise<any> {
        return Api.axios.get(URL.GROUP_DATA)
            .then((res: any) => {
                return Promise.resolve(res.data);
            })
    }

    public static addGroup(groupName: string): Promise<any> {
        return Api.axios.post(URL.ADD_GROUP, {groupName})
            .then((res: any) => {
                return Promise.resolve(res.data);
            })
    }

    public static removeGroup(groupName: string): Promise<any> {
        return Api.axios.post(URL.REMOVE_GROUP, {groupName})
            .then((res: any) => {
                return Promise.resolve(res.data);
            })
    }

    public static addGroupUser(data: any): Promise<any> {
        return Api.axios.post(URL.ADD_GROUP_USER, data)
            .then((res: any) => {
                return Promise.resolve(res.data);
            })
    }

    public static removeGroupUser(data: any): Promise<any> {
        return Api.axios.post(URL.REMOVE_GROUP_USER, data)
            .then((res: any) => {
                return Promise.resolve(res.data);
            })
    }

}
Api.init();

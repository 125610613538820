export const GameCategoryEnum: { [key: string]: string; } = {
    NONE: 'Vali kategooria',

    COUNTING: 'Counting',
    WORDS: 'Words',
    ADDING: 'Adding',
    SUBTRACTING: 'Subtracting',
    MULTIPLICATION: 'Multiplication',
}

export const GameCategoryEnumInverse: { [key: string]: string; } = {
    'Vali kategooria': 'NONE',

    'Counting': 'COUNTING',
    'Words': 'WORDS',
    'Adding': 'ADDING',
    'Subtracting': 'SUBTRACTING',
    'Multiplication': 'MULTIPLICATION',
}

export const GameCategoryNameEnum = {
    NONE: 'Vali kategooria',

    COUNTING: 'Loendamine',
    WORDS: 'Sõnad',
    ADDING: 'Liitmine',
    SUBTRACTING: 'Lahutamine',
    MULTIPLICATION: 'Korrutamine',
}

export const GameCategoryEnumMap: { [key: string]: string; } = {
    'Vali kategooria': 'NONE',

    'Loendamine': 'COUNTING',
    'Sõnad': 'WORDS',
    'Liitmine': 'ADDING',
    'Lahutamine': 'SUBTRACTING',
    'Korrutamine': 'MULTIPLICATION',
}


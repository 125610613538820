<template>
  <div class="home view container-lg"
       style="background-color: #FFF7C0; height: calc(100vh - 56px)">
    <!--    <img @click="onSubmit" alt="Vue logo" src="../assets/logo.png">-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js + TypeScript App"/>-->

    <div class="container-md">

      <h1 class="text-dark p-2 pt-5 pb-2" style="font-family: ribeye,sans-serif">Tere tulemast Nutitundi!</h1>
      <router-link to="/play">
        <button class="btn btn-lg m-4 bi bi-play" id="play-button" @click="onPlay"> Mine mängima!</button>
      </router-link>

      <div class="container mt-5">

        <div class="row my-0">
          <div class="col-12 col-sm-9">
            <img class="img-fluid" src="../assets/cloud.svg" alt="cloud" id="cloud-image">

            <div class="text-light h4 text-shadow category-block" style="top: -50%; position: relative;">
              <span style="font-family: Ribeye;">Tase 1</span>
              <br>
              <br>
              <span style="text-decoration: underline; font-family: Sigmar one; cursor: pointer"
                    @click="onLessonClick(enums.LEVEL_ONE_MATH)">Matemaatika</span>
              <br>
              <span style="text-decoration: underline; font-family: Sigmar one; cursor: pointer"
                    @click="onLessonClick(enums.LEVEL_ONE_WORDS)">Loendamine</span></div>
          </div>

          <div class="col-0 col-sm-3"></div>
        </div>

        <div class="row my-0">
          <div class="col-0 col-sm-3"></div>
          <div class="col-12 col-sm-9">
            <img class="img-fluid" src="../assets/balloon.svg" alt="balloon">

            <div class="text-light h4 text-shadow category-block" style="top: -65%; position: relative;">
              <span style="font-family: Ribeye;">Tase 2</span>
              <br>
              <br>
              <span style="text-decoration: underline; font-family: Sigmar one; cursor: pointer"
                    @click="onLessonClick(enums.LEVEL_TWO_MATH)">Matemaatika</span>
              <br>
              <span style="text-decoration: underline; font-family: Sigmar one; cursor: pointer"
                    @click="onLessonClick(enums.LEVEL_TWO_WORDS)">Keel ja Kõne</span></div>
          </div>
        </div>

      </div>


      <div class="align-content-start">Nutitund {{ new Date().getFullYear() }}</div>
    </div>

  </div>
</template>

<script lang="ts">
import enums from '@/core/enums/enums';
import {defineComponent, reactive, inject, ref} from 'vue';
import HelloWorld from '@/components/HelloWorld.vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {useToast} from "vue-toastification";
import router from "@/router";
import {GameCategoryEnum} from "@/core/enums/gameCategoryEnum";
import {GameDifficultyEnum} from "@/core/enums/gameDifficultyEnum"; // @ is an alias to /src

interface Book {
  title: string
  year?: number
}

export default defineComponent({
  name: 'Home',
  components: {
    HelloWorld,
  },
  setup(props, {attrs, slots, emit}) {
    const dataStore: any = inject('dataStore');

    const state = reactive({
      count: 0
    });

    const onLessonClick = (selection: string) => {
      let selectionObjectData = {};
      switch (selection) {
        case enums.LEVEL_ONE_MATH:
          selectionObjectData = {difficulty: GameDifficultyEnum.INTRODUCTION, category: GameCategoryEnum.ADDING}
          break;
        case enums.LEVEL_ONE_WORDS:
          selectionObjectData = {difficulty: GameDifficultyEnum.PRE_K, category: GameCategoryEnum.COUNTING}
          break;
        case enums.LEVEL_TWO_MATH:
          selectionObjectData = {difficulty: GameDifficultyEnum.Mid_K, category: GameCategoryEnum.MULTIPLICATION}
          break;
        case enums.LEVEL_TWO_WORDS:
          selectionObjectData = {difficulty: GameDifficultyEnum.Mid_K, category: GameCategoryEnum.WORDS}
          break;
      }
      const selectionData = JSON.stringify(selectionObjectData);

      router.push({
        name: 'Play',
        params: {selectionData}
      })
    }

    const onPlay = () => {
      warnToLogin();
    }

    const warnToLogin = () => {
      if (!dataStore.state.loggedIn)
        useToast().success('Logi sisse, et oma tulemused salvestada!');
    }

    return {
      state,
      dataStore,
      onLessonClick,
      onPlay,
      enums,
    }
  },
  emits: {
    // addBook(payload: { bookName: string }) {
    // perform runtime validation
    // return payload.bookName.length > 0
    // }
  },
  // data: {
  /*book: {
    type: Object as PropType<Book>,
    required: true
  }*/
  // },
  computed: { // will return a cashed value, if any of the dependant elements change, returned value will be re-computed
    /*colorCode: {
      get() {
        return this.$store.state.colorCode;
      },
      set(newValue) {
        console.log(newValue);
        this.$store.dispatch('setColorCode', newValue);
      }
    },*/
    /*styleObject: {
      color: 'red',
      fontSize: '13px'
    }*/
    ...mapGetters('game', ['itemsLength']),
  },
  /*watch: {
    firstName(val) {
      this.fullName = val + ' ' + this.lastName
    },
  },*/
  methods: {
    ...mapMutations(["increaseCounter"]),
    // ...mapMutations(),
    // ...mapActions(['updateGames']),
    /*onSubmit() {
      console.log(this.$store.state.counter);
      console.log(this.$store.getters['game/itemsLength']); // works!
      console.log(this.itemsLength); // works !!

      console.log(this.$store.getters.getHello);
      this.increaseCounter();
      // this.$emit('addBook', {
      //   bookName: "Hello from event addBook" // Type error!
      // })
      // this.$emit('non-declared-event') // Type error!
    }*/
  }
});
</script>

<style scoped lang="scss">

#play-button {
  font-size: 35px;
  background-color: #de7b7b;
  border-radius: 55px;
  padding: 25px;
  margin: 65px 0 !important;
}

@media (max-width: 575.98px) {
  #play-button {
    font-size: 21px;
    border-radius: 60px;
    padding: 21px;
  }
  .category-block {
    font-size: 20px;
  }
  #cloud-image {
    height: 200px;
    width: 250px;
  }


}

</style>

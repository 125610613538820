import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import Toast, {PluginOptions, POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css"; // Import the CSS or use your own!

import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const toastOptions: PluginOptions = {
    // You can set your default options here
    position: POSITION.BOTTOM_RIGHT,
    transition: "Vue-Toastification__fade",
};

createApp(App)
    .use(store)
    .use(router)
    .use(Toast, toastOptions)
    .mount('#app')



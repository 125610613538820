export const GameDifficultyEnum: { [key: string]: string; } = {
    NONE: 'Vali raskusaste',

    INTRODUCTION: 'Introduction',
    PRE_K: 'Pre-K',
    EARLY_K: 'Early-K',
    MID_K: 'Mid-K',
    LATE_K: 'Late-k',
}

export const GameDifficultyEnumInverse: { [key: string]: string; } = {
    'Vali raskusaste': 'NONE',

    'Introduction': 'INTRODUCTION',
    'Pre-K': 'PRE_K',
    'Early-K': 'EARLY_K',
    'Mid-K': 'MID_K',
    'Late-k': 'LATE_K',
}

export const GameDifficultyNameEnum = {
    NONE: 'Vali raskusaste',

    INTRODUCTION: 'Sissejuhatus',
    PRE_K: 'Pisipõnnid',
    EARLY_K: '4-5 aastased',
    MID_K: '5-6 aastased',
    LATE_K: '6-7 aastased',
}

export const GameDifficultyEnumMap: { [key: string]: string; } = {
    'Vali raskusaste': 'NONE',

    'Sissejuhatus': 'INTRODUCTION',
    'Pisipõnnid': 'PRE_K',
    '4-5 aastased': 'EARLY_K',
    '5-6 aastased': 'MID_K',
    '6-7 aastased': 'LATE_K',
}

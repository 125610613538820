import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import Home from "@/views/Home.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/play',
        name: 'Play',
        component: () => import('../views/Play.vue'),
        props: true,
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/Profile.vue')
    },
    {
        path: '/profile-groups',
        name: 'ProfileGroups',
        component: () => import('../views/ProfileGroups.vue')
    },
    {
        path: '/:pathMatch(.*)*', // used for 404 - not found
        name: 'NotFound',
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
    }
]

const router = createRouter({
    // todo, dont forget, users will get a 404 error if they access http://oursite.com/user/id directly in their browser.
    // add redirect to index file in your webserver, e.g. nginx:
    // location / {
    //   try_files $uri $uri/ /index.html;
    // }
    // history: createWebHistory(),
    history: createWebHashHistory(),
    routes
})

export default router

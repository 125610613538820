
import {defineComponent, inject} from 'vue';
import router from "@/router";

export default defineComponent({
  name: 'TopBar',
  props: {},
  setup(props, {attrs, slots, emit}) { // style, and id attributes, that have been passed into this component
    const dataStore: any = inject('dataStore');

    const onLogoClick = () => {
      router.push({name: 'Home'});
    };

    const onLogout = () => {
      dataStore.tokenGetters.tokenComputed.value = false; // remove token reactively

      dataStore.state.userPoints = 0;
      dataStore.state.username = '';
      dataStore.state.userRoles = [];
      dataStore.state.firstName = '';
      dataStore.state.lastName = '';
      dataStore.state.groupUsername = '';
      dataStore.state.birthday = '';
      dataStore.state.groups = [];
      dataStore.state.isUser = false;
      dataStore.state.isAdmin = false;
      dataStore.state.isGroupUser = false;


      router.push('/');     // TODO return home and points disappearing after logout and login
    }

    return {
      onLogoClick,
      dataStore,
      onLogout
    };
  }
});

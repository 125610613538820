<template>
  <TopBar></TopBar>
  <!--  <div id="nav">-->
  <!--    <router-link to="/">Home</router-link>-->
  <!--    |-->
  <!--    <router-link to="/about">About</router-link>-->
  <!--  </div>-->
  <router-view/>
</template>

<script>
import {inject, provide} from "vue";
import DataStore from "@/store/composition/dataStore";
import TopBar from "@/components/TopBar";
import Api from "@/core/Api";

export default {
  components: {
    TopBar,
  },
  setup() {
    provide('dataStore', DataStore);

    const getUserData = async () => {
      await Api.getUserData(DataStore);
    }
    if (DataStore.state.loggedIn) getUserData();
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

body {
  background-color: #FFF7C0;
}

.text-shadow {
  text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
  #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
}


//------------------------------
// Min-width breakpoints

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }
//@include media-breakpoint-up(sm) { ... }
//@include media-breakpoint-up(md) { ... }
//@include media-breakpoint-up(lg) { ... }
//@include media-breakpoint-up(xl) { ... }
//@include media-breakpoint-up(xxl) { ... }

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
//@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
//@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
//@media (min-width: 992px) { ... }

// X-Large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }

// XX-Large devices (larger desktops, 1400px and up)
//@media (min-width: 1400px) { ... }


//------------------------------
// Max-width

// No media query necessary for xs breakpoint as it's effectively `@media (max-width: 0) { ... }`
//@include media-breakpoint-down(sm) { ... }
//@include media-breakpoint-down(md) { ... }
//@include media-breakpoint-down(lg) { ... }
//@include media-breakpoint-down(xl) { ... }
//@include media-breakpoint-down(xxl) { ... }

// X-Small devices (portrait phones, less than 576px)
//@media (max-width: 575.98px) { ... }

// Small devices (landscape phones, less than 768px)
//@media (max-width: 767.98px) { ... }

// Medium devices (tablets, less than 992px)
//@media (max-width: 991.98px) { ... }

// Large devices (desktops, less than 1200px)
//@media (max-width: 1199.98px) { ... }

// X-Large devices (large desktops, less than 1400px)
//@media (max-width: 1399.98px) { ... }

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width


//------------------------------
// Single breakpoint

//@include media-breakpoint-only(xs) { ... }
//@include media-breakpoint-only(sm) { ... }
//@include media-breakpoint-only(md) { ... }
//@include media-breakpoint-only(lg) { ... }
//@include media-breakpoint-only(xl) { ... }
//@include media-breakpoint-only(xxl) { ... }

//For example the @include media-breakpoint-only(md) { ... } will result in start-of-md to end-of-md :
//@media (min-width: 768px) and (max-width: 991.98px) { ... }


//------------------------------
// Between breakpoints (NB! including breakpoints themselves)

//@include media-breakpoint-between(md, xl) { ... }

// Apply styles starting from medium devices and up to extra large devices
//@media (min-width: 768px) and (max-width: 1199.98px) { ... }

//------------------------------ END

</style>

import {reactive, computed, ref} from "vue";
import enums from "@/core/enums/enums";
import jwtDecode from "jwt-decode";

interface IState {
    [name: string]: any;
}

const tokenGetters: any = {
    tokenComputed: computed({
        get(): any {
            return window.localStorage.getItem(enums.ACCESS_TOKEN);
        },
        set(val: boolean | string) {
            if (typeof val === "string") {
                window.localStorage.setItem(enums.ACCESS_TOKEN, val);
                loggedInRef.value = true;
            } else if (!val) {
                window.localStorage.removeItem(enums.ACCESS_TOKEN);
                loggedInRef.value = false;
            }
            tokenRef.value = window.localStorage.getItem(enums.ACCESS_TOKEN);
        }
    }),
    getTokenUsername() {
        if (tokenRef.value && tokenRef.value.length > 0) {
            const decoded: any = jwtDecode(tokenRef.value);
            return decoded?.username;
        } else return '';
    },
    getTokenUserRoles() {
        if (tokenRef.value && tokenRef.value.length > 0) {
            const decoded: any = jwtDecode(tokenRef.value);
            return decoded?.roles;
        } else return '';
    },
}

const loggedInRef = ref(tokenGetters.tokenComputed.value !== null);
const tokenRef = ref(tokenGetters.tokenComputed.value);

const state: IState = reactive({
    loggedIn: loggedInRef, // reference this in site, to just get the value
    token: tokenRef, // reference this in site, to just get the value
    username: tokenGetters.getTokenUsername(), // email
    userRoles: tokenGetters.getTokenUserRoles(),

    userPoints: 0,
    firstName: '',
    lastName: '',
    groupUsername: '',
    birthday: '',
    groups: [],

    isUser: false,
    isAdmin: false,
    isGroupUser: false,
});

const methods = {
    decreaseCount() {
        state.count--;
    }
};

const getters = {
    getSquaredCount() {
        return state.count * state.count;
    }
};

const countComputed = computed({
    get() {
        return state.count * 2;
    },
    set(val: number) {
        state.count = val;
    }
})

export default {
    state, // state: readonly(state) // from "vue" package, to make state readonly, not allow to change publicly
    getters,
    tokenGetters,
    countComputed,
    methods,
}
